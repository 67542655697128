import { Box, Fade, Stack, useMediaQuery, useTheme } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { useEffect, useState } from "react";
import { SwitchTransition } from "react-transition-group";
import { l_manifesto } from "../../locales";
import ManifestoTypography from "../../theme/ManifestoTypography";

//@ts-ignore
import mosaicBLImg1 from "../../images/mosaic-bigleft-1.png";
//@ts-ignore
import mosaicBLImg2 from "../../images/mosaic-bigleft-2.png";
//@ts-ignore
import mosaicBLImg3 from "../../images/mosaic-bigleft-3.png";
//@ts-ignore
import mosaicBLImg4 from "../../images/mosaic-bigleft-4.png";
//@ts-ignore
import mosaicBRImg1 from "../../images/mosaic-bigright-1.png";
//@ts-ignore
import mosaicBRImg2 from "../../images/mosaic-bigright-2.png";
//@ts-ignore
import mosaicBRImg3 from "../../images/mosaic-bigright-3.png";
//@ts-ignore
import mosaicBRImg4 from "../../images/mosaic-bigright-4.png";
//@ts-ignore
import mosaicSLImg1 from "../../images/mosaic-smallleft-1.png";
//@ts-ignore
import mosaicSLImg2 from "../../images/mosaic-smallleft-2.png";
//@ts-ignore
import mosaicSLImg4 from "../../images/mosaic-smallleft-4.png";
//@ts-ignore
import mosaicSRImg3 from "../../images/mosaic-smallright-3.png";

const PicturesSection = ({ openCard }: { openCard: number }) => {
  const theme = useTheme();
  const isOverLg = useMediaQuery(theme.breakpoints.up("lg"));
  const isOverMd = useMediaQuery(theme.breakpoints.up("md"));

  const CONTAINER_H = !isOverMd
    ? "400px"
    : isOverLg
    ? "min(700px, 80vh)"
    : "min(500px, 80vh)";
  const BIGRECT_H = isOverMd ? `calc(${CONTAINER_H}*0.7 - 24px)` : CONTAINER_H;
  const SMALLRECT_H = `calc(${CONTAINER_H}*0.3)`;
  const OPACITY_ANIM_DURATION = 0.3;

  const imagesBigLeft = [
    mosaicBLImg1,
    mosaicBLImg2,
    mosaicBLImg3,
    mosaicBLImg4,
  ];

  const imagesBigRight = [
    mosaicBRImg1,
    mosaicBRImg2,
    mosaicBRImg3,
    mosaicBRImg4,
  ];

  const imagesSmallLeft = [mosaicSLImg1, mosaicSLImg2, "", mosaicSLImg4];
  const imagesSmallRight = ["", "", mosaicSRImg3, ""];

  useEffect(() => {
    const tl = gsap.timeline();
    tl.to(".bigLeft-1", {
      transform: !isOverMd
        ? "translateX(-50%) translateY(0px)"
        : openCard === 0
        ? `translateY(calc(0px - ${CONTAINER_H} + ${BIGRECT_H}))`
        : openCard === 1
        ? `translateY(calc(0px - ${CONTAINER_H}))`
        : openCard === 2
        ? "translateY(100%)"
        : "translateY(0px)",
    })
      .to(
        ".bigLeft-2",
        {
          transform: !isOverMd
            ? "translateX(-50%) translateY(0px)"
            : openCard === 0
            ? `translateY(100%)`
            : openCard === 1
            ? `translateY(0px)`
            : openCard === 2
            ? `translateY(calc(0px - ${CONTAINER_H} + ${BIGRECT_H}))`
            : `translateY(calc(0px - ${CONTAINER_H}))`,
        },
        "="
      )
      .to(
        ".smallLeft-1",
        {
          transform:
            openCard === 0
              ? `translateY(0px)`
              : openCard === 1
              ? `translateY(calc(0px - ${CONTAINER_H} + ${SMALLRECT_H}))`
              : openCard === 2
              ? `translateY(calc(0px - ${CONTAINER_H}))`
              : `translateY(calc(0px - ${CONTAINER_H}))`,
        },
        "="
      )
      .to(
        ".smallLeft-2",
        {
          transform:
            openCard === 0
              ? `translateY(100%)`
              : openCard === 1
              ? `translateY(100%)`
              : openCard === 2
              ? `translateY(calc(0px)`
              : `translateY(calc(0px - ${CONTAINER_H} + ${SMALLRECT_H}))`,
        },
        "="
      )
      .to(
        ".bigRight-1",
        {
          transform:
            openCard === 0
              ? `translateY(0px)`
              : openCard === 1
              ? `translateY(calc(0px - ${CONTAINER_H} + ${BIGRECT_H}))`
              : openCard === 2
              ? `translateY(calc(0px - ${CONTAINER_H}))`
              : `translateY(calc(0px - ${CONTAINER_H}))`,
        },
        "="
      )
      .to(
        ".bigRight-2",
        {
          transform:
            openCard === 0
              ? `translateY(100%)`
              : openCard === 1
              ? `translateY(100%)`
              : openCard === 2
              ? `translateY(calc(0px))`
              : `translateY(calc(0px - ${CONTAINER_H} + ${BIGRECT_H}))`,
        },
        "="
      )
      .to(
        ".smallRight-1",
        {
          transform:
            openCard === 0
              ? `translateY(calc(0px - ${CONTAINER_H} + ${SMALLRECT_H}))`
              : openCard === 1
              ? `translateY(calc(0px - ${CONTAINER_H}))`
              : openCard === 2
              ? `translateY(100%)`
              : `translateY(0px)`,
        },
        "="
      )
      .to(
        ".smallRight-2",
        {
          transform:
            openCard === 0
              ? `translateY(100%)`
              : openCard === 1
              ? `translateY(0px)`
              : openCard === 2
              ? `translateY(calc(0px - ${CONTAINER_H} + ${SMALLRECT_H}))`
              : `translateY(calc(0px - ${CONTAINER_H}))`,
        },
        "="
      )
      .to(
        ".bigLeft-img0",
        {
          opacity: openCard === 0 ? 1 : 0,
          duration: OPACITY_ANIM_DURATION,
        },
        "="
      )
      .to(
        ".bigLeft-img3",
        {
          opacity: openCard === 3 ? 1 : 0,
          duration: OPACITY_ANIM_DURATION,
        },
        "="
      )
      .to(
        ".bigLeft-img1",
        {
          opacity: openCard === 1 ? 1 : 0,
          duration: OPACITY_ANIM_DURATION,
        },
        "="
      )
      .to(
        ".bigLeft-img2",
        {
          opacity: openCard === 2 ? 1 : 0,
          duration: OPACITY_ANIM_DURATION,
        },
        "="
      )
      .to(
        ".bigRight-img0",
        {
          opacity: openCard === 0 ? 1 : 0,
          duration: OPACITY_ANIM_DURATION,
        },
        "="
      )
      .to(
        ".bigRight-img3",
        {
          opacity: openCard === 3 ? 1 : 0,
          duration: OPACITY_ANIM_DURATION,
        },
        "="
      )
      .to(
        ".bigRight-img1",
        {
          opacity: openCard === 1 ? 1 : 0,
          duration: OPACITY_ANIM_DURATION,
        },
        "="
      )
      .to(
        ".bigRight-img2",
        {
          opacity: openCard === 2 ? 1 : 0,
          duration: OPACITY_ANIM_DURATION,
        },
        "="
      )
      .to(
        ".smallLeft-img0",
        {
          opacity: openCard === 0 ? 1 : 0,
          duration: OPACITY_ANIM_DURATION,
        },
        "="
      )
      .to(
        ".smallLeft-img3",
        {
          opacity: openCard === 3 ? 1 : 0,
          duration: OPACITY_ANIM_DURATION,
        },
        "="
      )
      .to(
        ".smallLeft-img1",
        {
          opacity: openCard === 1 ? 1 : 0,
          duration: OPACITY_ANIM_DURATION,
        },
        "="
      )
      .to(
        ".smallLeft-img2",
        {
          opacity: openCard === 2 ? 1 : 0,
          duration: OPACITY_ANIM_DURATION,
        },
        "="
      )
      .to(
        ".smallRight-img0",
        {
          opacity: openCard === 0 ? 1 : 0,
          backgroundColor: "rgb(255, 122, 0)",
          duration: OPACITY_ANIM_DURATION,
        },
        "="
      )
      .to(
        ".smallRight-img1",
        {
          opacity: openCard === 1 ? 1 : 0,
          backgroundColor: "rgb(87, 214, 249)",
          duration: OPACITY_ANIM_DURATION,
        },
        "="
      )
      .to(
        ".smallRight-img2",
        {
          opacity: openCard === 2 ? 1 : 0,
          backgroundColor: "transparent",
          duration: OPACITY_ANIM_DURATION,
        },
        "="
      )
      .to(
        ".smallRight-img3",
        {
          opacity: openCard === 3 ? 1 : 0,
          backgroundColor: "rgb(241, 244, 255)",
          duration: OPACITY_ANIM_DURATION,
        },
        "="
      );

    return () => {
      // tl.kill(); //? do not kill, causes reset
    };
  }, [openCard, isOverMd, isOverLg]);

  return (
    <Box
      sx={{
        width: "100%",
        height: CONTAINER_H,
        position: "relative",
        zIndex: 1,
      }}
    >
      {imagesBigLeft.map((img, i) => (
        <Box
          key={`first-gr-${imagesBigLeft.length - i}`}
          sx={{
            position: { md: "absolute", xs: "absolute" },
            bottom: 0,
            left: { md: 0, xs: "50%" },
            width: { md: "calc(50% - 12px)", xs: "100%" },
            height: BIGRECT_H,
            // backgroundColor: i === openCard ? "red" : "red",
            borderRadius: "24px",
            zIndex: 5 - i,
            backgroundImage: `url(${img})`,
            backgroundSize: "cover",
            backgroundPosition: "50% 50%",
            // opacity: i % 3 === 0 ? 1 : 0,
          }}
          className={`bigLeft-${i % 3 === 0 ? 1 : 2} bigLeft-img${i}`}
        />
      ))}

      {isOverMd && (
        <>
          {imagesBigRight.map((img, i) => (
            <Box
              key={`second-gr-${imagesBigRight.length - i}`}
              sx={{
                position: "absolute",
                bottom: 0,
                right: 0,
                width: "calc(50% - 12px)",
                height: BIGRECT_H,
                // backgroundColor: i === openCard ? "red" : "red",
                borderRadius: "24px",
                zIndex: 5 - i,
                backgroundImage: `url(${img})`,
                backgroundSize: "cover",
                backgroundPosition: "50% 50%",

                // opacity: i % 3 === 0 ? 1 : 0,
              }}
              className={`bigRight-${i <= 1 ? 1 : 2} bigRight-img${i}`}
            />
          ))}
          {imagesSmallLeft.map((img, i) => (
            <Box
              key={`first-sm-gr-${imagesSmallLeft.length - i}`}
              sx={{
                position: "absolute",
                bottom: 0,
                left: 0,
                width: "calc(50% - 12px)",
                height: SMALLRECT_H,
                backgroundColor:
                  i === 2 && openCard === 2
                    ? "rgb(183, 69, 246)"
                    : "transparent",
                borderRadius: "120px",
                zIndex: 5 - i,
                backgroundImage: `url(${img})`,
                backgroundSize: "cover",
                backgroundPosition: "50% 50%",

                // opacity: i <= 1 ? 1 : 0,
              }}
              className={`smallLeft-${i <= 1 ? 1 : 2} smallLeft-img${i}`}
            />
          ))}
          {imagesSmallRight.map((img, i) => (
            <Box
              key={`second-sm-gr-${imagesSmallRight.length - i}`}
              sx={{
                position: "absolute",
                bottom: 0,
                right: 0,
                width: "calc(50% - 12px)",
                height: SMALLRECT_H,
                borderRadius: "120px",
                zIndex: 5 - i,
                backgroundImage: `url(${img})`,
                backgroundSize: "cover",
                backgroundPosition: "50% 50%",

                opacity: i % 3 === 0 ? 1 : 0,
              }}
              className={`smallRight-${i % 3 === 0 ? 1 : 2} smallRight-img${i}`}
            />
          ))}
        </>
      )}
    </Box>
  );
};

const Role = ({
  title,
  listOfPoints,
  openCard,
}: {
  title: string;
  listOfPoints: [string, string, string];
  openCard: number;
}) => {
  const theme = useTheme();
  const isOverSm = useMediaQuery(theme.breakpoints.up("sm"));
  const isOverMd = useMediaQuery(theme.breakpoints.up("md"));
  gsap.registerPlugin(ScrollTrigger);

  useEffect(() => {
    if (!isOverSm) {
      gsap.to(".mosaicBluePill", {
        scrollTrigger: {
          trigger: ".mosaicBluePill",
          scrub: 2,
        },
        x: -100,
      });
      gsap.to(".mosaicPurpleCircle", {
        scrollTrigger: {
          trigger: ".mosaicPurpleCircle",
          scrub: 2,
        },
        x: 80,
        y: 20,
      });
    }
    if (isOverSm && !isOverMd) {
      gsap.to(".mosaicBluePill", {
        scrollTrigger: {
          trigger: ".mosaicBluePill",
          scrub: 2,
        },
        x: 50,
      });
      gsap.to(".mosaicPurpleCircle", {
        scrollTrigger: {
          trigger: ".mosaicPurpleCircle",
          scrub: 2,
        },
        x: -90,
        y: -20,
      });
    }
  }, [isOverSm, isOverMd]);

  return (
    <Stack
      sx={{
        flexDirection: { md: "row", xs: "column" },
        justifyContent: "space-between",
        alignItems: "center",
        gap: "32px",
      }}
    >
      <Box
        sx={{
          width: { md: "50%", xs: "100%" },
          maxWidth: { md: "650px", xs: "unset" },
          position: "relative",
        }}
      >
        {!isOverSm && (
          <>
            <Box
              sx={{
                position: "absolute",
                width: "25px",
                left: "10%",
                bottom: "-20%",
                zIndex: 2,
              }}
              className="mosaicPurpleCircle"
            >
              <StaticImage
                alt=""
                src="../../images/purple-circle.svg"
                width={25}
              />
            </Box>
            <Box
              sx={{
                position: "absolute",
                width: "40px",
                right: "-5%",
                top: "-25%",
                zIndex: 2,
              }}
              className="mosaicBluePill"
            >
              <StaticImage alt="" src="../../images/blue-pill.svg" width={40} />
            </Box>
          </>
        )}
        <ManifestoTypography
          variant="overtitle"
          sx={{
            color: "rgba(0, 0, 0, 0.6)",
            fontWeight: 400,
            marginBottom: { md: "32px", xs: "8px" },
          }}
        >
          {l_manifesto("manyDefinitions.mosaicOvertitle")}
        </ManifestoTypography>

        <SwitchTransition>
          <Fade
            key={title}
            appear
            easing={theme.transitions.easing.sharp}
            timeout={{ enter: 500, exit: 300 }}
          >
            <ManifestoTypography
              variant="bigTitle"
              sx={{ marginBottom: { md: "56px", xs: "30px" } }}
            >
              {title}
            </ManifestoTypography>
          </Fade>
        </SwitchTransition>

        <Stack sx={{ flexDirection: "column", gap: "32px" }}>
          <Stack
            sx={{
              flexDirection: "row",
              gap: "24px",
              height: { md: "50px", xs: "40px" },
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                width: { xs: "32px", md: "40px" },
                height: { xs: "32px", md: "40px" },
                transition: "transform 400ms ease-in",
                transitionDelay: "50ms",
                transform: `rotate(${90 * openCard}deg)`,
                flexShrink: 0,
              }}
            >
              <StaticImage
                src="../../images/mosaic-point-1.svg"
                alt={""}
                width={40}
              />
            </Box>

            <SwitchTransition>
              <Fade
                key={title}
                appear
                easing={theme.transitions.easing.easeIn}
                timeout={{ enter: 300, exit: 100 }}
              >
                <ManifestoTypography variant="body1">
                  {listOfPoints[0]}
                </ManifestoTypography>
              </Fade>
            </SwitchTransition>
          </Stack>

          <Stack
            sx={{
              flexDirection: "row",
              gap: "24px",
              height: { md: "50px", xs: "40px" },
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                width: { xs: "32px", md: "40px" },
                height: { xs: "32px", md: "40px" },

                transition: "transform 400ms ease-in",
                transitionDelay: "100ms",
                transform: `rotate(${90 * openCard}deg)`,
                flexShrink: 0,
              }}
            >
              <StaticImage
                src="../../images/mosaic-point-2.svg"
                alt={""}
                width={40}
              />
            </Box>
            <SwitchTransition>
              <Fade
                key={title}
                appear
                easing={theme.transitions.easing.easeIn}
                timeout={{ enter: 300, exit: 100 }}
              >
                <ManifestoTypography variant="body1">
                  {listOfPoints[1]}
                </ManifestoTypography>
              </Fade>
            </SwitchTransition>
          </Stack>

          <Stack
            sx={{
              flexDirection: "row",
              gap: "24px",
              height: { md: "50px", xs: "40px" },
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                width: { xs: "32px", md: "40px" },
                height: { xs: "32px", md: "40px" },

                transition: "transform 500ms ease-in",
                transform: `rotate(${90 * openCard}deg)`,
                flexShrink: 0,
              }}
            >
              <StaticImage
                src="../../images/mosaic-point-3.svg"
                alt={""}
                width={40}
              />
            </Box>
            <SwitchTransition>
              <Fade
                key={title}
                appear
                easing={theme.transitions.easing.easeIn}
                timeout={{ enter: 300, exit: 100 }}
              >
                <ManifestoTypography variant="body1">
                  {listOfPoints[2]}
                </ManifestoTypography>
              </Fade>
            </SwitchTransition>
          </Stack>
        </Stack>
      </Box>

      {isOverSm && (
        <Box
          sx={{
            width: { md: "50%", xs: "100%" },
            maxWidth: { md: "700px", xs: "400px" },
            marginRight: { md: "unset", xs: "auto" },
            marginLeft: { md: "auto", xs: "auto" },
            position: "relative",
          }}
        >
          <PicturesSection openCard={openCard} />
          {!isOverMd && (
            <>
              <Box
                sx={{
                  position: "absolute",
                  width: "30px",
                  height: "30px",
                  left: "5%",
                  bottom: "-5%",
                  zIndex: 2,
                }}
                className="mosaicPurpleCircle"
              >
                <StaticImage
                  alt=""
                  src="../../images/purple-circle.svg"
                  width={30}
                />
              </Box>
              <Box
                sx={{
                  position: "absolute",
                  width: "50px",
                  right: "-5%",
                  top: "15%",
                  zIndex: 2,
                }}
                className="mosaicBluePill"
              >
                <StaticImage
                  alt=""
                  src="../../images/blue-pill.svg"
                  width={50}
                />
              </Box>
            </>
          )}
        </Box>
      )}
    </Stack>
  );
};

const RolesMosaic = () => {
  gsap.registerPlugin(ScrollTrigger);
  const [openCard, setOpenCard] = useState(0);

  const roles: { title: string; points: [string, string, string] }[] = [
    {
      title: l_manifesto("manyDefinitions.mosaicTitle1"),
      points: [
        l_manifesto("manyDefinitions.mosaic1LP1"),
        l_manifesto("manyDefinitions.mosaic1LP2"),
        l_manifesto("manyDefinitions.mosaic1LP3"),
      ],
    },
    {
      title: l_manifesto("manyDefinitions.mosaicTitle2"),
      points: [
        l_manifesto("manyDefinitions.mosaic2LP1"),
        l_manifesto("manyDefinitions.mosaic2LP2"),
        l_manifesto("manyDefinitions.mosaic2LP3"),
      ],
    },
    {
      title: l_manifesto("manyDefinitions.mosaicTitle3"),
      points: [
        l_manifesto("manyDefinitions.mosaic3LP1"),
        l_manifesto("manyDefinitions.mosaic3LP2"),
        l_manifesto("manyDefinitions.mosaic3LP3"),
      ],
    },
    {
      title: l_manifesto("manyDefinitions.mosaicTitle4"),
      points: [
        l_manifesto("manyDefinitions.mosaic4LP1"),
        l_manifesto("manyDefinitions.mosaic4LP2"),
        l_manifesto("manyDefinitions.mosaic4LP3"),
      ],
    },
  ];

  useEffect(() => {
    const t = gsap.timeline({
      scrollTrigger: {
        trigger: ".mosaic-container",
        scrub: 3,
        // markers: true,
        pin: true,
        start: "center center",
        end: "+=2000",
        onUpdate: (trigger) => {
          setOpenCard(() =>
            Math.max(0, Math.ceil(trigger.progress / (1 / roles.length)) - 1)
          );
        },
      },
    });
    return () => {
      t.scrollTrigger?.kill();
      t.kill();
    };
  }, []);

  return (
    <Box
      sx={{
        marginBottom: { md: "400px", xs: "104px" },
        width: "90%",
        margin: "auto",
        maxWidth: "1800px",
      }}
      className="mosaic-container"
    >
      <Role
        title={roles[openCard].title}
        listOfPoints={roles[openCard].points}
        openCard={openCard}
      />
    </Box>
  );
};

export default RolesMosaic;
