import { Box, Stack, useMediaQuery, useTheme } from "@mui/material";
import { l_manifesto } from "../../locales";
import ManifestoTypography from "../../theme/ManifestoTypography";
import Decorations from "./Decorations";
import PhotoCarousel from "./PhotoCarousel";

const Title = () => {
  const theme = useTheme();
  const isOverMd = useMediaQuery(theme.breakpoints.up("md"));
  return (
    <Box>
      <Box
        sx={{
          margin: "auto",
          paddingTop: { md: "200px", xs: "20px" },
          paddingBottom: { md: "220px", xs: "100px" },
        }}
      >
        <Stack
          sx={{
            flexDirection: "row",
            boxSizing: "border-box",
            maxWidth: "1700px",
            margin: "auto",
            marginBottom: { md: "220px", xs: "45px" },
            width: "90%",
          }}
        >
          <Box
            sx={{
              width: { md: "60%", xs: "100%" },
              maxWidth: "800px",
              // border: "1px solid green"
            }}
          >
            <ManifestoTypography
              variant="overtitle"
              sx={{ marginBottom: { md: "56px", xs: "16px" } }}
            >
              manifesto
            </ManifestoTypography>
            <ManifestoTypography
              variant="bigTitle"
              sx={{
                marginBottom: { md: "56px", xs: "16px" },
                maxWidth: "900px",
              }}
            >
              {l_manifesto("title.title")}
            </ManifestoTypography>
            <ManifestoTypography
              variant="body1"
              sx={{ color: "rgba(0, 0, 0, 0.6)" }}
            >
              {l_manifesto("title.description")}
            </ManifestoTypography>
          </Box>
          {isOverMd && (
            <Box
              sx={{
                width: "40%",
                maxWidth: "550px",
                marginLeft: "auto",
                // border: "1px solid lime"
              }}
            >
              <Decorations />
            </Box>
          )}
        </Stack>
        <PhotoCarousel />
      </Box>
    </Box>
  );
};

export default Title;
