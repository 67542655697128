/** @jsxImportSource @emotion/react */

import SEO from "../../components/old/seo";
import Footer from "../../components/shared/Footer/Footer";
import APlaceToBelong from "./components/APlaceToBelong/APlaceToBelong";
import ManyDefinitions from "./components/ManyDefinitions/ManyDefinitions";
import NoEndPoint from "./components/NoEndPoint/NoEndPoint";
import ProjectBelievers from "./components/ProjectBelievers/ProjectBelievers";
import ResearchAndDevelopment from "./components/ResearchAndDevelopment/ResearchAndDevelopment";
import ScrollingCards from "./components/ScrollingCards/ScrollingCards";
import Title from "./components/Title/Title";
import { l_manifesto } from "./locales";

const Manifesto = () => {
  return (
    <>
      <SEO title="Manifesto" description={l_manifesto("seo.description")} />
      <Title />
      <NoEndPoint />
      <ManyDefinitions />
      <ProjectBelievers />
      <ResearchAndDevelopment />
      <ScrollingCards />
      <APlaceToBelong />
      <Footer />
    </>
  );
};

export default Manifesto;
