import { DeepKeyof, language } from "../../traductions";
const traduction = {
  it: {
    seo: {
      description:
        "Innovazione e creatività sono i principi su cui basiamo lo sviluppo di prodotti digitali, aiutati da React, Flutter, Node.js e Figma.",
    },
    title: {
      title: "Vogliamo creare la nostra Silicon Valley in Italia",
      overtitle: "Manifesto",
      description: "Ambizioso, ma non abbiamo paura di dirlo.",
    },
    noEndPoint: {
      title: "Non c’è nessun punto di arrivo",
      description:
        "Siamo dei perenni insoddisfatti. A volte viene percepito negativamente, ma è ciò che ci spinge a migliorarci ogni giorno. Sappiamo che anche quando avremo raggiunto la cima della montagna ne vedremo un’altra da scalare e di questo andiamo fieri.",
    },
    manyDefinitions: {
      overtitle: "Chi siamo?",
      title: "Ci piace definirci in molti modi",
      carouselW1: "Innovativi",
      carouselW2: "Designer",
      carouselW3: "Razionali",
      carouselW4: "Responabili",
      carouselW5: "Curiosi",
      carouselW6: "Coraggiosi",
      carouselW7: "Simpatici",
      carouselW8: "Esperti di energy drink",
      carouselW9: "Aggiornati",
      carouselW10: "Creativi",
      carouselW11: "Simpatici",
      carouselW12: "Freschi",
      carouselW13: "Coerenti",
      carouselW14: "Sviluppatori",
      carouselW15: "Campioni di ping pong",
      carouselW16: "Determinati",
      carouselW17: "Forti",
      carouselW18: "Riconoscibili",
      carouselW19: "Trasparenti",
      carouselW20: "Bad jokes experts",
      carouselW21: "Dinamici",
      carouselW22: "Tecnologici",
      carouselW23: "Empatici",
      carouselW24: "Bellissimi",
      mosaicTitle1: "Sviluppo",
      mosaicTitle2: "Design",
      mosaicTitle3: "Workshop",
      mosaicTitle4: "Supporto dev",
      mosaicOvertitle:
        "Vogliamo azzerare l’attrito di interazione con i prodotti digitali attraverso",
      mosaic1LP1: "App mobile crossplatform e native",
      mosaic1LP2: "Sviluppo frontend web in React + Typescript",
      mosaic1LP3: "Sviluppo backend e cloud deploy",
      mosaic2LP1: "Analisi basate su metodologie di design thinking",
      mosaic2LP2: "Design UX/UI",
      mosaic2LP3: "Test utente",
      mosaic3LP1: "Analisi basate su metodologie di design thinking",
      mosaic3LP2: "Design Sprint",
      mosaic3LP3: "Restituzione visiva e analisi benchmark",
      mosaic4LP1: "Consulenza su Flutter",
      mosaic4LP2: "Consulenza su React",
      mosaic4LP3: "Test utente",
    },
    projectBelievers: {
      title: "Ci siamo davvero per i progetti in cui crediamo",
      description:
        "Vogliamo innovare, sul serio. Per farlo sappiamo che oltre ad avere le persone migliori servono le idee migliori. Per questo abbiamo attivato una modalità di <b>supporto startup in cambio di equity</b>. Diventiamo partner?",
      buttonText: "Candida la tua startup",
    },
    researchAndDevelopment: {
      title: "Ci alimentiamo di ricerca e sviluppo",
      gridTitle1: "Flutter",
      gridTitle2: "Figma",
      gridTitle3: "React e React-Native",
      gridTitle4: "Node.js",
      gridDescription1:
        "Unificare le codebase delle nostre app con Flutter ci consente di ottimizzare il mantenimento dei progetti senza sacrificarne le performace, permettendoci di concentrarci sulla logica e sulle interazioni.",
      gridDescription2:
        "Figma come tool di progettazione ci permette di condividere in tempo reale le informazioni importanti con tutto il team. Negli anni, è diventato la nostra spalla per il design.",
      gridDescription3:
        "Padroneggiamo React e React-Native, grazie ai quali riusciamo a dare il massimo sia sul web che sul mobile.",
      gridDescription4:
        "Il nostro backend viene sviluppato in Node.js con Typescript. Realizziamo microservizi containerizzati e scalabili con Docker e li distribuiamo su Google Cloud e AWS.",
    },
    aPlaceToBelong: {
      title: "Un luogo a cui appartenere, coltivando talento e innovazione",
      description:
        "Vogliamo circondarci di persone appassionate, amiamo quello che facciamo e vogliamo trasmetterlo.",
      buttonText: "Scopri la vita a Mabiloft",
    },
    scrollingCards: {
      overtitle: "Cosa aspettarsi lavorando con noi?",
      title:
        "Crediamo che il successo sia il risultato di un lavoro di squadra, di una visione ambiziosa e di una costante ricerca dell'eccellenza.",
      card1Title: "Il design è l’anima del nostro lavoro",
      card1Description:
        "Progettiamo prodotti digitali studiando flussi, user journey e wireframe. È il solido fondamento dell’interfaccia, che applica i principi del design thinking.",
      card2Title: "Proattività",
      card2Description:
        "Teniamo ai progetti dei nostri clienti come se fossero nostri. Siamo i primi a cercare miglioramenti e soluzioni adatte agli obiettivi prefissati.",
      card3Title: "Una passione a tempo pieno",
      card3Description:
        "L’amore per quello che facciamo ci spinge a sperimentare e trovare sempre la soluzione migliore per i nostri progetti.",
      card4Title: "Amiamo metterci alla prova",
      card4Description:
        "Accompagniamo i nostri prodotti anche nella fase di test, rendendo la produzione un continuo scambio di feedback in modo da portare la qualità del software sempre al massimo",
      card5Title: "Siamo soprattutto ciò che succede nel mentre",
      card5Description:
        "I nostri progetti non si limitano al prodotto finale, creiamo sempre un legame duraturo e di fiducia reciproca.",
    },
  },
  en: {
    seo: {
      description:
        "Innovation e creativity are the principles on which we base the development of our digital products, with the help of React, Flutter, Node.js and Figma.",
    },
    title: {
      title: "We want to create our Silicon Valley in Italy",
      overtitle: "Manifesto",
      description: "Ambitious, but we are not afraid to say it.",
    },
    noEndPoint: {
      title: "There is no end point",
      description:
        "We are eternally unsatisfied. Somethimes this is perceived as negative, but it's what pushes us to improve everyday. We know that even when we will reach the top, we will find a higher goal and we are proud of that.",
    },
    manyDefinitions: {
      overtitle: "Who are we?",
      title: "We like to call ourselves in many ways",
      carouselW1: "Innovative",
      carouselW2: "Designers",
      carouselW3: "Rational",
      carouselW4: "Responsible",
      carouselW5: "Curious",
      carouselW6: "Brave",
      carouselW7: "Friendly",
      carouselW8: "Energy drink experts",
      carouselW9: "Up-to-date",
      carouselW10: "Creative",
      carouselW11: "Friendly",
      carouselW12: "Cool",
      carouselW13: "Consistent",
      carouselW14: "Developers",
      carouselW15: "Table tennis champions",
      carouselW16: "Determined",
      carouselW17: "Strong",
      carouselW18: "Recognizable",
      carouselW19: "Trasparent",
      carouselW20: "Bad jokes experts",
      carouselW21: "Active",
      carouselW22: "Technological",
      carouselW23: "Sympathetic",
      carouselW24: "Beautiful",
      mosaicTitle1: "Development",
      mosaicTitle2: "Design",
      mosaicTitle3: "Workshop",
      mosaicTitle4: "Dev support",
      mosaicOvertitle:
        "We want to get rid of the friction in the interaction with digital products through",
      mosaic1LP1: "Crossplatform and native mobile apps",
      mosaic1LP2: "Frontend web development in React + Typescript",
      mosaic1LP3: "Backend development and cloud deploy",
      mosaic2LP1: "Analytics based on design thinking",
      mosaic2LP2: "UX/UI design",
      mosaic2LP3: "User test",
      mosaic3LP1: "Analytics based on design thinking",
      mosaic3LP2: "Design Sprint",
      mosaic3LP3: "Visual presentation and benchmark analysis",
      mosaic4LP1: "Flutter consulting",
      mosaic4LP2: "React consulting",
      mosaic4LP3: "User test",
    },
    projectBelievers: {
      title: "We are truly there for the projects we believe in",
      description:
        "We seriously want to innovate. To do so, we know that besides the best people we need the best ideas. That's why we engaged in a procedure of <b>startup support in exchange of equity</b>. Would you like to be our partner?",
      buttonText: "Submit your startup",
    },
    researchAndDevelopment: {
      title: "We live for research and development",
      gridTitle1: "Flutter",
      gridTitle2: "Figma",
      gridTitle3: "React and React-Native",
      gridTitle4: "Node.js",
      gridDescription1:
        "Unifying our apps' codebases with Flutter helps to optimise the manteinance of the projects without compromising on performace, allowing to focus on the logic ant the interactions.",
      gridDescription2:
        "Figma as a tool for design allows to share real-time infos with the whole team. Over the years, it became a useful assistant for design.",
      gridDescription3:
        "We mastered React and React-Native, that help us give our best both on the web and on mobile.",
      gridDescription4:
        "Our backend is developed in Node.js with Typescript. We create containerized and scalable microservices with Docker deployed on Google Cloud and AWS.",
    },
    aPlaceToBelong: {
      title: "A place to belong, nurturing talent and innovation",
      description:
        "We want to be surrounded by passionate people, we love what we do and want to share it.",
      buttonText: "Learn more about life at mabiloft",
    },
    scrollingCards: {
      overtitle: "What should one expect when working with us?",
      title:
        "We believe that success is the result of teamwork, ambitious perspective and constant pursuit of excellence.",
      card1Title: "Design is the soul of our work",
      card1Description:
        "We plan digital products by studying flows, user journeys and wireframes. That's our firm ground for interfaces, that applies principles of design thinking.",
      card2Title: "Proactivity",
      card2Description:
        "We care about our clients' projects as if they were ours. We are the first ones to look for improvements and suitable solutions to reach the desired goals.",
      card3Title: "A full-time passion",
      card3Description:
        "Love is what leads us to try out new solutions and find the best on for our projects.",
      card4Title: "We love to challenge ourselves",
      card4Description:
        "We support our products even during the testing process, making the production a continuous feedback exchange to elevate our software quality to the top.",
      card5Title: "We are especially what happens in the process",
      card5Description:
        "Our projects are not only the final result, we always create a solid and trustworthy bond.",
    },
  },
};

export const l_manifesto = (
  trad: DeepKeyof<typeof traduction[keyof typeof traduction]>
) => {
  const tokens = trad.split(".");
  let resource = traduction && (traduction as any)[language.code];
  if (!resource) {
    return tokens[tokens.length - 1];
  }

  for (let c = 0; c < tokens.length; c++) {
    resource = resource[tokens[c]];
    if (!resource) {
      // resource not found
      return trad;
    }
  }

  return resource;
};
